












































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private open(item: any) {
    this.$emit("open", item);
  }
}
