import { render, staticRenderFns } from "./wutaixingge.vue?vue&type=template&id=46a10e11&scoped=true&"
import script from "./wutaixingge.vue?vue&type=script&lang=ts&"
export * from "./wutaixingge.vue?vue&type=script&lang=ts&"
import style0 from "./wutaixingge.vue?vue&type=style&index=0&id=46a10e11&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a10e11",
  null
  
)

export default component.exports