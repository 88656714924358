import { render, staticRenderFns } from "./sizhen-detail.vue?vue&type=template&id=6fc158bd&scoped=true&"
import script from "./sizhen-detail.vue?vue&type=script&lang=ts&"
export * from "./sizhen-detail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc158bd",
  null
  
)

export default component.exports