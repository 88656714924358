










































































































import { Component, Vue, Prop } from "vue-property-decorator";
import laonianren from "@/components/duijie/sizhen/detail/laonianren.vue";
import zhongyi from "@/components/duijie/sizhen/detail/zhongyi.vue";
import sizhenhecan from "@/components/duijie/sizhen/detail/sizhenhecan.vue";
import childSmall from "@/components/duijie/sizhen/detail/child-small.vue";
import childMiddle from "@/components/duijie/sizhen/detail/child-middle.vue";
import woman from "@/components/duijie/sizhen/detail/woman.vue";
import tangniaobing from "@/components/duijie/sizhen/detail/tangniaobing.vue";
import gaoxueya from "@/components/duijie/sizhen/detail/gaoxueya.vue";
import she from "@/components/duijie/sizhen/detail/she.vue";
import mian from "@/components/duijie/sizhen/detail/mian.vue";
import mai from "@/components/duijie/sizhen/detail/mai.vue";
import jingdianchufang from "@/components/duijie/sizhen/detail/jingdianchufang.vue";
import wutaixingge from "@/components/duijie/sizhen/detail/wutaixingge.vue";
import wutaixinggeSimple from "@/components/duijie/sizhen/detail/wutaixingge-simple.vue";
import wuzangxiangyin from "@/components/duijie/sizhen/detail/wuzangxiangyin.vue";
import zhineng from "@/components/duijie/sizhen/detail/zhineng.vue";
import jingluo from "@/components/duijie/sizhen/detail/jingluo.vue";
import zhongchengyao from "@/components/duijie/sizhen/detail/zhongchengyao.vue";
@Component({
  components: {
    laonianren,
    zhongyi,
    sizhenhecan,
    childSmall,
    childMiddle,
    woman,
    tangniaobing,
    gaoxueya,
    she,
    mian,
    mai,
    jingdianchufang,
    wutaixingge,
    wutaixinggeSimple,
    wuzangxiangyin,
    zhineng,
    jingluo,
    zhongchengyao,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private currentData: any = {}; // 方案建议弹框内容
  private ifShowDialog: boolean = false;
  private open(item: any) {
    if (item.type === "file") {
      window.open(item.path);
    } else {
      this.currentData = item;
      this.ifShowDialog = true;
    }
  }
}
