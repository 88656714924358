var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail"},[_c('div',{staticClass:"top-title"},[_c('div',[_vm._v(_vm._s(_vm.data["报告名称"]))]),_c('div',{staticClass:"time"},[_vm._v("采集时间："+_vm._s(_vm.data["检测时间"]))])]),_c('div',{staticClass:"content"},[_c('div',[_c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("脉象结论：")]),_c('div',[_vm._v(_vm._s(_vm.data["脉象结论"]))])]),_c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("局部脉搏波形图：")]),(_vm.data['局部脉搏波形图'] && _vm.data['局部脉搏波形图'].length > 0)?_c('div',{staticClass:"echarts"},[_c('VueEcharts',{staticClass:"echarts-box",attrs:{"option":_vm.boxingtu}})],1):_vm._e(),(!_vm.data['局部脉搏波形图'] || _vm.data['局部脉搏波形图'].length == 0)?_c('Empty',{attrs:{"text":'暂无数据!'}}):_vm._e()],1),_c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("经络柱状图：")]),(
            _vm.data['经络柱状图'] &&
            _vm.data['经络柱状图'].data &&
            _vm.data['经络柱状图'].data.length > 0
          )?_c('div',{staticClass:"echarts"},[_c('VueEcharts',{staticClass:"echarts-box",attrs:{"option":_vm.zhuzhuangtu}})],1):_vm._e(),(
            (!_vm.data['经络柱状图'] && !_vm.data['经络柱状图'].data) ||
            _vm.data['经络柱状图'].data.length == 0
          )?_c('Empty',{attrs:{"text":'暂无数据!'}}):_vm._e()],1),_c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("心率：")]),_c('div',{staticClass:"table"},[_c('div',{staticClass:"cell header"},_vm._l((_vm.data['心率']['title']),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"cell"},_vm._l((_vm.data['心率']['data']),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }