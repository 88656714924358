
































































import { Component, Vue, Prop } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private zhuzhuangtu: any = {};
  private boxingtu: any = {};
  private open(url: any) {
    window.open(url);
  }
  private drawBar() {
    this.zhuzhuangtu = {
      color: ["#0a97a2"],
      tooltip: {
        show: true,
        trigger: "item",
      },
      grid: {
        left: "5%",
        right: "2%",
        top: "40",
        bottom: "40",
      },
      xAxis: {
        type: "category",
        axisLabel: {
          color: "rgba(0,0,0,0.45)",
        },
        axisLine: {
          lineStyle: {
            color: "rgba(0,0,0,0.45)",
          },
        },
        data: this.data["经络柱状图"].title,
      },
      yAxis: {
        name: "",
        type: "value",
        axisLine: {
          show: true,
        },
      },
      series: [
        {
          type: "bar",
          data: this.data["经络柱状图"].data,
          itemStyle: {
            color: "#6C99F6",
          },
        },
      ],
    };
  }
  private drawLine() {
    const xAxis: any = [];
    for (let index = 0; index < this.data["局部脉搏波形图"]; index++) {
      xAxis.push(index + 1);
    }
    this.boxingtu = {
      color: ["#0a97a2"],
      xAxis: {
        type: "category",
        data: xAxis,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: this.data["局部脉搏波形图"],
          type: "line",
          smooth: true,
        },
      ],
    };
  }
  private mounted() {
    this.drawBar();
    this.drawLine();
  }
}
